@import '@/styles/theme/variables.scss';

.markdownContent {
  width: $displayViews-markdownContent-width;
  border-left: $token-border-width-default solid $token-border-color;
  padding-left: $token-spacing-8 !important;
  padding-top: $displayViews-markdownContent-padding-top !important;
  flex: $displayViews-markdownContent-flex !important;

  p {
    line-height: $token-line-height-loose;
    margin-top: $token-spacing-3;
    font-weight: $token-font-weight-reg;
    font-size: $token-font-size-root;
  }

  h1 {
    @include headH1;
    font-weight: $token-font-weight-bold;
    margin-bottom: $token-spacing-6;
  }

  h2 {
    font-size: $productDocumentPage-h2-font-size;
    font-weight: $productDocumentPage-h2-font-weight;
    line-height: $token-spacing-9;
    margin-top: $token-spacing-8;
  }

  h3 {
    font-size: $productDocumentPage-h3-font-size;
    font-weight: $token-font-weight-med;
    line-height: $token-spacing-6;
    letter-spacing: $token-spacing-0;
    margin-top: $token-spacing-6;
    word-wrap: break-word;
  }

  h4 {
    font-size: $token-font-size-base;
    font-weight: $token-font-weight-med;
    line-height: $token-line-height-loose;
    letter-spacing: $token-spacing-0;
    margin-top: $token-spacing-6;
  }

  h5 {
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-med;
    line-height: $token-line-height-loose;
    letter-spacing: $token-letter-spacing-loose;
    margin-top: $token-spacing-6;
  }

  ul {
    @include listUl;
    padding-inline-start: $token-spacing-10;
  }

  ol {
    @include listOl;
    padding-inline-start: $token-spacing-10;
  }

  li {
    font-size: $token-font-size-root;
    line-height: $token-line-height-loose;
  }

  pre,
  ol,
  ul {
    width: 100%;
  }

  pre {
    white-space: pre-wrap;
    margin-top: $token-spacing-3;
    background-color: $codeBlock-pre-background-color;

    code {
      font-size: $token-font-size-root !important;
      color: $token-color-base-light-gray;
      background-color: $codeBlock-code-background-color;
      font-weight: $token-font-weight-reg !important;
    }
  }
}

.headerSectionSandboxEnabled {
  width: 100%;
}

.markdownContentProductSections {
  @extend .markdownContent;
  border: none;
  padding: 0 !important;
}

.contentWrapper {
  width: 100%;

  div > div > div > p > a {
    text-decoration: underline;
  }
}

.documentList {
  list-style-type: none !important;
  padding-inline-start: $token-spacing-0 !important;
}

.TOCSectionTitle {
  font-size: $token-font-size-14px;
  font-weight: $token-font-weight-med;
  color: $token-background-dark;
  text-transform: uppercase;
  letter-spacing: $token-spacing-xs;
  padding-left: $token-spacing-5px !important;
}

.tableOfContentSectionWrapper {
  position: sticky;
  top: $token-spacing-0;
  padding-top: $displayViews-MDTOCSectionWrapper-padding-top !important;
  flex: $displayViews-MDTOCSectionWrapper-flex;

  @media #{$usb-max-lg} {
    display: none !important;
  }
}

.MDTOCSectionWrapper {
  a {
    color: $token-background-dark;
    text-decoration: none !important;
    line-height: $token-line-height-21px;
  }

  div.MDTOCSectionContent {
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-bold;
    padding-left: $token-spacing-2;
    line-height: $token-line-height-21px;
    overflow-y: auto;
    margin-top: $displayViews-MDTOCSectionWrapper-MDTOCSectionContent-margin-top;
  }

  ul {
    list-style-type: none !important;
    margin: $token-spacing-0 !important;
    padding-inline-start: $token-spacing-0 !important;
    line-height: $token-line-height-32px;

    li a:hover {
      color: $token-text-color-interaction !important;
    }

    &.TOCMenuList ul {
      list-style-type: none;
    }
  }
} 

.documentPageMDContent {
  width: 100%;
}

.leftSideColumn {
  position: sticky !important;
  top: $displayViews-leftSideColumn-top !important;
  padding-left: $token-spacing-0 !important;
  padding-top: $displayViews-leftSideColumn-padding-top !important;
  padding-right: $displayViews-leftSideColumn-padding-right !important;
  flex: $displayViews-leftSideColumn-flex;

  .productNameTitle {
    font-weight: $token-font-weight-med;
    font-size: $token-font-size-sm;
    line-height: $token-line-height-21px;
    letter-spacing: $token-spacing-xs;
    text-transform: uppercase;
  }

  .leftSideViewDocuments {
    padding-top: $token-spacing-6;
    padding-bottom: $token-spacing-128px;

    &.withScroll {
      height: 100vh;
      overflow: hidden;
      overflow-y: scroll;
      width: 100%;
    }

    [data-testid='productDocuments'] {
      margin-bottom: $token-spacing-1;

      a,
      span {
        @include menuLink;
      }

      @include menuLinkFocused;
    }

    .active {
      color: $token-text-color-interaction !important;
    }

    .linkseparator {
      border-left: $token-border-width-3px solid $token-text-color-interaction;
      display: block;
      height: $token-spacing-8;
      float: left;
      position: absolute;
      left: $token-spacing-15px;
    }
  }
}

.productOverviewDropdown {
  border: $token-border-width-default solid $token-color-grey-60 !important;
  border-width: $token-border-width-0;
  border-radius: $token-rounded-sm;
}

a.selectedTocTitle {
  border-left: $token-border-width-large solid
    $token-background-brand-interaction !important;
  color: $token-background-brand-interaction !important;
}

.TOCMenuList {
  a {
    line-height: $token-line-height-loose;
    padding: $token-spacing-small;
    display: inline-block;
    padding-left: $token-spacing-5px;
    border-left: $token-border-width-large solid transparent;
    word-break: $displayViews-TOCMenuList-a-word-break;
  }

  li {
    margin: $token-spacing-4 $token-spacing-0 $token-spacing-4 $token-spacing-0;
  }
}

.markdownContent table,
.markdownContent td,
.markdownContent th {
  color: $token-text-color-secondary;
  border-collapse: collapse !important;
  margin-bottom: $token-spacing-21px !important;
}

.markdownContent td,
.markdownContent th {
  padding: $token-spacing-21px !important;
  font-size: $token-font-size-sm;
  font-weight: $token-font-weight-reg !important;
  text-align: initial;
}

.markdownContent tr:nth-child(even) {
  background-color: $token-background-secondary;
}

.markdownContent table {
  border: $token-border-width-default solid $token-border-base !important;
  width: 100% !important;
  margin-top: $token-spacing-4;
  table-layout: fixed;

  thead {
    border: $token-border-width-default solid $token-border-base !important;

    th {
      font-style: normal;
      font-weight: $token-font-weight-med !important;
      font-size: $token-font-size-sm;
      line-height: $token-line-height-21px;
      color: $token-color-grey-90;
    }
  }

  td {
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-reg !important;
    word-break: $displayViews-markdownContent-table-td-word-break;
  }
}

.markdownContent td:nth-child(2),
.markdownContent th:nth-child(2) {
  padding-left: $token-spacing-0 !important;
}

.markdownContent td {
  ul,
  ol {
    padding-inline-start: $token-spacing-3 !important;
  }
}

@media #{$usb-max-820} {
  .MDTOCSectionWrapper {
    display: none;
  }
}

.gridContainer {
  margin-bottom: $token-spacing-20 !important;
}

// Smaller than LG screen size
@media #{$usb-max-lg} {
  .markdownContent ul li:last-of-type {
    margin-bottom: $token-spacing-0;
  }

  .markdownContent {
    border-left: $token-rounded-square;
    padding-left: $token-spacing-0 !important;
    padding-right: $token-spacing-0 !important;
  }

  .leftSideColumn {
    display: none !important;
  }

  .MDTOCSectionWrapper {
    display: none !important;
  }

  .tocDropdownWrapper {
    margin: $token-spacing-6 0;

    button {
      width: 100% !important;
    }
  }
}

.addProjectBtn {
  float: left;
  margin: $token-spacing-6 $token-spacing-6 $token-spacing-0 $token-spacing-0 !important;
}

.productOverviewDropdown {
  float: left;
  margin-right: $token-spacing-6;

  @media #{$usb-max-sm} {
    margin-top: $token-spacing-0 !important;
    margin-left: $token-spacing-0 !important;
  }
}

.productDocument {
  color: $token-background-dark;
}

.documentLink {
  border-left: $token-border-width-large solid $token-border-interaction !important;
  color: $link-basic-text-color !important;
}

@media #{$usb-max-sm} {
  .addProjectBtn {
    margin: $token-spacing-6 $token-spacing-112px $token-spacing-0
      $token-spacing-0 !important;
    width: 100%;
  }

  .markdownContent {
    p {
      word-break: $displayViews-markdownContent-p-word-break;
      white-space: normal;
    }
    
    td {
      word-break: $displayViews-markdownContent-td-word-break;
      white-space: normal;
    }
  }

  .productOverviewDropdown {
    width: 100%;

    button {
      width: 100% !important;
    }

    ul {
      width: 100% !important;
    }

    li {
      margin: $token-spacing-0 !important;
    }
  }

  .markdownContent {
    width: 100%;
  }

  .markdownContent td,
  .markdownContent th {
    padding: $token-border-radius-7px !important;
  }
}

@media #{$usb-max-600} {
  .markdownContent table {
    border: none !important;

    thead {
      display: none;
    }

    td {
      display: flex !important;
      margin: $token-spacing-10px;
      padding: unset !important;
      font-weight: $token-font-weight-reg !important;
      font-size: $token-font-size-base;
      line-height: $token-line-height-loose;
      color: $token-background-dark;

      &::before {
        content: attr(label) !important;
        width: $token-spacing-115px !important;
        min-width: $token-spacing-115px !important;
      }
    }

    tr {
      border-bottom: $token-border-width-2-05px solid $token-border-bottom-color;
      background-color: unset !important;
    }
  }
}

@media screen and (min-width: $token-breakpoint-large) {
  .tocDropdownWrapper {
    display: none !important;
  }
}

.tocDropdownWrapper {
  display: block;
}

.markdownContent ol:first-child {
  padding: $token-spacing-0 !important;
  margin-top: $token-spacing-0;
}

@media #{$usb-max-sm} {
  .markdownContent table tbody tr th {
    display: none;
  }

  .markdownContent table td,
  .markdownContent table th {
    display: block;
  }

  .markdownContent table td[data-th]:before {
    content: attr(data-th);
    margin-right: $token-spacing-10px;
    font-weight: bold;
  }

  .addProjectBtnWrapper {
    width: 100%;
  }
}

.addProjectBtnWrapper {
  margin: $token-spacing-0 $token-spacing-6 $token-spacing-0 $token-spacing-0 !important;
}

.gridWrapper {
  @media #{$usb-max-lg} {
    padding-left: $token-spacing-29px !important;
  }
}

.rightCol {
  padding-left: $token-spacing-0;

  @media screen and (max-width: 1780px) {
    padding-left: $token-spacing-4;
  }

  @media screen and (max-width: $token-spacing-1650px) {
    padding-left: $token-spacing-8;
  }

  @media screen and (max-width: 1620px) {
    padding-left: $token-spacing-10;
  }

  @media screen and (max-width: 1580px) {
    padding-left: $token-spacing-12;
  }

  @media screen and (max-width: 1580px) {
    padding-left: $token-spacing-14;
  }

  @media screen and (max-width: 1480px) {
    padding-left: $token-spacing-16;
  }

  @media screen and (max-width: 1400px) {
    padding-left: $token-spacing-20;
  }

  @media screen and (max-width: 1343px) {
    padding-left: $token-spacing-2;
  }

  @media screen and (max-width: 1320px) {
    padding-left: 0.8rem;
  }

  @media screen and (max-width: 1300px) {
    padding-left: 1.2rem;
  }

  @media screen and (max-width: 1270px) {
    padding-left: 1.6rem;
  }

  @media screen and (max-width: 1245px) {
    padding-left: $token-spacing-8;
  }

  @media screen and (max-width: 1210px) {
    padding-left: $token-spacing-10;
  }

  @media screen and (max-width: 1195px) {
    padding-left: $token-spacing-12;
  }

  @media screen and (max-width: 1160px) {
    padding-left: $token-spacing-14;
  }

  @media screen and (max-width: 1105px) {
    padding-left: $token-spacing-16;
  }

  @media #{$usb-max-lg} {
    padding-left: $token-spacing-0;
  }
}

.centerLinkIcon {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;

  span {
    padding: 0 !important;

    svg {
      margin: 0 !important;
    }
  }
}

.topPadding {
  padding-top: $token-spacing-0 !important;
}

.redocDiv {
  margin: $token-spacing-0 auto;
  padding-left: $displayViews-redocDiv-padding-left;

  div[id*='react-tabs'] {
    p {
      color: $token-utility-white !important;
    }
  }

  ul {
    list-style: disc !important;
  }

  h2 {
    font-size: $token-font-size-2xl !important;
    font-weight: $token-font-weight-med !important;
  }

  blockquote {
    li {
      color: $token-color-base-dark-blue-gray;

    }
  }

  code {
    a {
      color: $token-background-brand-interaction;
    }
  }
  

  @media #{$usb-max-lg} {
    padding-left: $token-spacing-0;
  }
}

.version {
  margin-top: $token-spacing-3;
  font-size: $token-font-size-sm;
  font-weight: $token-font-weight-med;
  text-transform: uppercase;
  min-width: $token-spacing-207px;
  letter-spacing: $token-spacing-xs;
}

.backLink {
  text-decoration: none;
  margin-top: $token-spacing-8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: $token-spacing-200px;

  a {
    font-size: $token-font-size-sm;
    font-weight: $token-font-weight-med;
    color: $link-basic-text-color;
    text-decoration: none;
  }
}

.leftArrow {
  margin-right: $token-spacing-12px;
}

.markdownTitle {
  @include headingFont;
  margin-bottom: $token-spacing-6;
  margin-top: $token-spacing-4;
  color: $token-background-inverse-primary;
  line-height: $token-spacing-10;

  p {
    @include headH1;
  }
}

.headerSection {
  width: 103%;
  border-radius: $token-spacing-0 $token-spacing-7 $token-spacing-7
  $token-spacing-0;
  left: -$token-spacing-8;
  position: relative;

  nav ol {
    margin-left: $token-spacing-8;
    padding-top: $token-spacing-4;
  }

  h1 {
    margin-left: $token-spacing-8;
    font-size: $token-font-size-4xl;
    font-weight: 700;
    color: $token-text-color-primary;
  }

  .addProjectBtnWrapper {
    margin: $token-spacing-4 $token-spacing-0 $token-spacing-0 $token-spacing-8 !important;
  }
}

.headerSectionMobile {
  display: none;
}

.tocHeadingMobile {
  display: none;
}

@media screen and (min-width: 360px) and (max-width: $token-breakpoint-large) {
  .headerSection {
    display: none;
  }

  .tocHeadingMobile {
    display: block;
    font-weight: $token-font-weight-reg;
    font-size: $token-spacing-3;
    color: $token-text-color-secondary;
    padding-top: $token-spacing-4;
  }

  .headerSectionMobile {
    background-color: $token-background-secondary;
    width: 100%;
    border-radius: $token-spacing-0 $token-spacing-7 $token-spacing-7
      $token-spacing-0;
    padding-bottom: $token-spacing-8;
    display: block;

    nav ol {
      padding-top: $token-spacing-4;
    }

    h1 {
      font-size: $token-spacing-8;
      font-weight: 700;
      line-height: $token-spacing-10;
      color: $token-text-color-primary;
    }
  }

  .headerSectionLayout {
    padding: $token-spacing-0 !important;
  }
}
